import React, { Dispatch } from "react";

const DEFAULT_LANG: Language =
  (process.env.GATSBY_DEFAULT_LANG as Language) || "zh";

export enum LANGUAGE_ACTIONS {
  setLanguage = "SET_LANGUAGE",
  setLanguages = "SET_LANGUAGES",
}

export type LanguageState = {
  DEFAULT_LANG: Language;
  language: Language;
  languages: Language[];
};

export type Action =
  | {
      type: LANGUAGE_ACTIONS.setLanguage;
      payload: Language;
    }
  | {
      type: LANGUAGE_ACTIONS.setLanguages;
      payload: Language[];
    };

export const setLanguage: (data: Language) => {
  type: LANGUAGE_ACTIONS.setLanguage;
  payload: Language;
} = (data) => ({
  type: LANGUAGE_ACTIONS.setLanguage,
  payload: data,
});

export const setLanguages: (data: Language[]) => {
  type: LANGUAGE_ACTIONS.setLanguages;
  payload: Language[];
} = (data) => ({
  type: LANGUAGE_ACTIONS.setLanguages,
  payload: data,
});

export const INITIAL_STATE = {
  DEFAULT_LANG,
  language: DEFAULT_LANG,
  languages: ["zh" as Language, "en" as Language],
};

export const useLanguageContext = React.createContext<{
  state: LanguageState;
  dispatch: Dispatch<Action>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const LanguageProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(LanguageReducer, INITIAL_STATE);

  return (
    <useLanguageContext.Provider value={{ state, dispatch }}>
      {children}
    </useLanguageContext.Provider>
  );
};

export function LanguageReducer(state: LanguageState, action: Action) {
  switch (action.type) {
    case LANGUAGE_ACTIONS.setLanguage: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case LANGUAGE_ACTIONS.setLanguages: {
      return {
        ...state,
        languages: action.payload,
      };
    }
    default: {
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
